import { Box } from "@mui/material"
import { ElementType, useEffect, useState } from "react"
import SideBar from "../core/components/sidebar/Sidebar"
import { onAuthStateChanged, getAuth } from "firebase/auth"
import { FirebaseUtil } from "../core/utils/firebase"

interface IProps {
    children: ElementType
}

const styles = {
    fullHeight: {
        height: "100vh",
    },

    contentPadding: {
        padding: "4vh",
    },
}

interface ServerProps {
    // user: string | null
}


export function withAuthLayout(props: IProps) {
    return function AuthContainer(appProps: ServerProps) {
        const [user, setUser] = useState<string | null | undefined>()
        // const { data } = useMeQuery("me", { skip: !user, pollingInterval: 150000 })

        useEffect(() => {
            setTimeout(() => {
                onAuthStateChanged(getAuth(), (user) => {
                    if (!FirebaseUtil.getInstance().getAuthActiveState()) return
                    if (user?.email) setUser(user.email)
                })
            }, 0)
        }, [])

        return true ? (
            <>
                <Box display="flex">
                    <SideBar />
                    <Box
                        flex={1}
                        sx={{ ...styles.fullHeight, ...styles.contentPadding }}
                    >
                        <Box style={{ minHeight: "90vh" }}>
                            <props.children {...appProps} />
                        </Box>
                    </Box>
                </Box>
            </>
        ) : (
            <div>Loading....</div>
        )
    }
}



/**
 * whereabouts: bus_number_DD_MM_YY_HH_MM: {
 *  id bus_number_DD_MM_YY_HH_MM:
 *  uid: uid,
 *  bus_number: bus_number,
 *  epoch: firebase.firestore.Timestamp.fromDate,
 * }
 */