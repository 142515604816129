import React, { lazy, Suspense } from 'react';
import { withAuthLayout } from './AuthWrapper'
import { Box, Grid } from '@mui/material';
import { LazyLoadingFragment } from '../core/components/LazyLoadings';
import DashboardTable from '../dashboard/DashboardLists'


const AuthButtons = lazy(() => import('../dashboard/DashboardActions'));

const DashbaordComponent: React.FC = () => {
    return (
        <Grid container justifyContent="center">
            <Box mb={3} width={'100%'}>
                <Suspense fallback={LazyLoadingFragment()}>
                    <AuthButtons />
                </Suspense>
            </Box>
            <Grid item xs={9}>
                <Box textAlign="center">
                    <DashboardTable />
                </Box>
            </Grid>
        </Grid>
    );
};

export default withAuthLayout({
    children: DashbaordComponent,
})