



export function LazyLoadingPlainText() {
    return <div>Loading</div>
}

export function LazyLoadingFragment() {
    return <></>
}