import { initializeApp } from "firebase/app";
import { signOut, getAuth, onAuthStateChanged, getIdToken, User, GoogleAuthProvider, signInWithPopup, browserLocalPersistence } from "firebase/auth"
import { getAnalytics } from "firebase/analytics";
import { getFirestore, serverTimestamp } from "firebase/firestore";

export enum DATABASES {
    WHEREABOUTS = "whereabouts"
}
export class FirebaseUtil {
    private static _instance: FirebaseUtil
    private isActive: boolean = true
    private app = initializeApp({
        apiKey: "AIzaSyBvgqnvQD2CTseHsOmNHmZsU5K_RQ4pUXU",
        authDomain: "busrestro.firebaseapp.com",
        databaseURL: "https://busrestro-default-rtdb.asia-southeast1.firebasedatabase.app",
        projectId: "busrestro",
        storageBucket: "busrestro.appspot.com",
        messagingSenderId: "829362002822",
        appId: "1:829362002822:web:ab84079a2b4f8e54d7ea07",
        measurementId: "G-T4VZNQLG75"
    });
    private constructor() { }

    static getInstance() {
        if (!FirebaseUtil._instance) FirebaseUtil._instance = new FirebaseUtil()
        return FirebaseUtil._instance
    }

    initialize() {
        this.app.automaticDataCollectionEnabled = false
        getAnalytics(this.app);
    }

    getFirestore() {
        return getFirestore(this.app)
    }

    serverTimestamp() {
        return serverTimestamp()
    }

    toggleAuthActive(active = true) {
        this.isActive = active
    }
    getAuthActiveState() {
        return this.isActive
    }

    async signout() {
        try {

            await signOut(getAuth())
            // deleteCookie("user")
            window.location.href = "/login"
        } catch (e) { }
    }

    currentSignedInUser() {
        return getAuth().currentUser
    }

    async getCurrentUser(): Promise<User | null> {
        return new Promise((resolve, reject) => {
            const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
                unsubscribe()
                resolve(user)
            }, reject)
        })
    }

    async login() {
        await getAuth(this.app).setPersistence(browserLocalPersistence)
        const { user } = await signInWithPopup(getAuth(this.app), new GoogleAuthProvider())
        return user
    }

    async getIdToken() {
        const user = await this.getCurrentUser()
        if (user)
            return getIdToken(user)
        return null
    }
}
