import React from 'react';
import ReactDOM from 'react-dom/client';
import { reportWebVitals, enableLocationPermission, enableCameraPermission } from './startupUtils';
import { ThemeProvider, createTheme } from "@mui/material"
import { theme } from './core/models/theme'
import { FirebaseUtil } from './core/utils/firebase'
import { Provider } from 'react-redux'
import { makeStore } from './core/redux/store'
import { routes } from "./pages/routes"
import { BrowserRouter, Route, Routes } from 'react-router-dom';

FirebaseUtil.getInstance().initialize()
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={makeStore}>
    <ThemeProvider theme={createTheme(theme)}>
      <React.StrictMode>
        <BrowserRouter>
          <Routes>
            {routes.map((ele) => (
              <Route path={ele.path} element={<ele.component />} key={ele.path} />
            ))}
          </Routes>
        </BrowserRouter>

      </React.StrictMode>
    </ThemeProvider>
  </Provider>
);

enableLocationPermission()
enableCameraPermission()
reportWebVitals(console.log);
