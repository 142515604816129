import { ReportHandler } from 'web-vitals';

export const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};
export function enableLocationPermission() {
  if (navigator.geolocation) {
    navigator.permissions.query({name:'geolocation'}).then(permissionStatus => {
      if (permissionStatus.state === 'denied') {
        alert('Please allow location access.');
        window.location.href = "app-settings:location";
      } 
    });
  } else {
    alert('Geolocation is not supported in your browser.');
  }
}

export function enableCameraPermission() {
  navigator.mediaDevices.getUserMedia({ video: true })
  .then(function(stream) {
    stream.getTracks().forEach(track => track.stop());
  })
  .catch(function(error) {
    alert('Please allow camera access.');
    window.location.href = "app-settings:camera";
  });
}
