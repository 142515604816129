import {
    Box,
    Divider,
    Drawer,
    IconButton,
    Tooltip,
} from "@mui/material"
import { FirebaseUtil } from "../../utils/firebase"
import { useLocation } from "react-router-dom"
import SidebarButton from "./SidebarButton"
import { useMeQuery } from "../../api/auth"
import { GroupOutlined, PowerSettingsNewOutlined } from "@mui/icons-material"
import { useEffect } from "react"

const styles = {
    dimensions: {
        width: "66px",
    },
    sidebarWidth: {},
    logo: {
        height: "30px",
        cursor: "pointer",
    },
    sidebarLogo: {
        width: "100%",
        textAlign: "center",
    },

    wrapper: {
        width: "100%",
        paddingTop: "5vh",
        paddingBottom: "5vh",
    },
}

export default function SideBar() {
    const { pathname } = useLocation()
    const { data } = useMeQuery("me")

    useEffect(() => {
        const loadBadgeValues = async () => {
            try {


            } catch (error) {
                console.error(error)
            }
        }

        loadBadgeValues()
    }, [])

    return (
        <Drawer variant="permanent" sx={styles.dimensions}>
            <Box
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                }}
            >
                <Box display="flex" flexDirection="column">
                    <Box sx={styles.sidebarLogo} pt={2} pb={2}>
                        <img
                            src="https://busrestro.com/assets/636a071040d40d7c8a7d6233_logo.png"
                            width={45}
                            height={25}
                            alt="Quillbot"
                        />
                    </Box>
                    <Divider style={{ marginBottom: "8px" }} />

                    <SidebarButton
                        href="/"
                        focused={["/", "/user"].includes(pathname)}
                        tooltip="Attendance Management"
                        role={data?.roles?.role}
                    >
                        <GroupOutlined />
                    </SidebarButton>

                </Box>
                <Box>
                    <Tooltip title="Log Out" placement="right">
                        <Box textAlign="center" m={1}>
                            <IconButton
                                onClick={() => FirebaseUtil.getInstance().signout()}
                                color="primary"
                            >
                                <PowerSettingsNewOutlined />
                            </IconButton>
                        </Box>
                    </Tooltip>
                </Box>
            </Box>
        </Drawer>
    )
}
