import { ButtonHTMLAttributes } from "react"

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  iconName: string
  fontWeight?: number
}
export default function LineIcon(props: IProps) {
  return (
    <i
      className={`${props.iconName} ${props.className}`}
      style={{ ...props.style, fontWeight: props.fontWeight || 400 }}
    />
  )
}
