import { Box, BoxProps, useTheme } from "@mui/material"
import boxShadows from "../utils/shadows"


export default function ShadowContainer(
  props: BoxProps & { hasBorder?: boolean }
) {

  const theme = useTheme()
  const styles = {
    shadow: {
      borderRadius: "14px",
      backgroundColor: "#fff",
      transition: "all 0.5s ease 0s",
      "&:hover": {
        boxShadow: boxShadows[0],
      },
      // height: "100%",
    },
    primary: {
      border: `1px dashed ${theme.palette.primary.light}`,
      borderRadius: "14px",
    },
  }

  return (
    <Box
      {...props}
      className={`${styles.shadow} ${props.className} ${props.hasBorder ? styles.primary : ""
        }`}
    >
      {props.children}
    </Box>
  )
}
