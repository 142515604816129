function hexToRGB(hex: string, alpha: number) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16)

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + "%)"
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")"
  }
}

const boxShadows = [
  `${hexToRGB("#4dd47e", 25)} 0px -3px 64px -20px, #ffffff 0px 30px 60px -30px`,
]

export default boxShadows
