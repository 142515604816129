import { Badge, Box, IconButton, useTheme, Tooltip, Link } from "@mui/material"

export default function SidebarButton({
    badge,
    children,
    focused,
    href,
    tooltip,
    role,
}: {
    badge?: boolean | string | number
    children: any
    focused: boolean
    href: string
    tooltip: string
    role: any
}) {
    const theme = useTheme()
    const styles = {
        focused: {
            border: `1px dashed ${theme.palette.primary.main}`,
        },
        unfocused: {
            border: "1px dashed transparent",
        },
    }

    return (
        <Link href={href}>
            <Tooltip title={tooltip} placement="right">
                <Box textAlign="center" m={1}>
                    <IconButton
                        color={focused ? "primary" : "default"}
                        sx={focused ? styles.focused : styles.unfocused}
                    >
                        <Badge
                            invisible={!badge}
                            color="error"
                            max={99}
                            badgeContent={
                                ["string", "number"].includes(typeof badge) ? badge : undefined
                            }
                        >
                            {children}
                        </Badge>
                    </IconButton>
                </Box>
            </Tooltip>
        </Link>
    )
}
