import { createApi, fakeBaseQuery } from "@reduxjs/toolkit/query/react"
import { SessionUser } from "../models/user"
import { FirebaseUtil } from "../utils/firebase"

export const profileAPI = createApi({
    baseQuery: fakeBaseQuery(),
    endpoints: (builder) => ({
        me: builder.query<SessionUser, string>({
            queryFn(){
                const user = FirebaseUtil.getInstance().getCurrentUser() as unknown as SessionUser
                const {email, full_name, uid, custom_claims, roles, profile} = user
                if (user) return  { data: {
                    email, full_name, uid, custom_claims, roles, profile

                }}
                else return { error: { message: 'No user found' } };
            }
        })
    })
})
export const { useMeQuery } = profileAPI