import {
    Typography,
    Grid,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Link
} from "@mui/material"
import ShadowContainer from "../core/components/ShadowContainer"
import { useEffect, useState } from "react"
import { DATABASES, FirebaseUtil } from "../core/utils/firebase"
import { collection, query, where, getDocs, doc } from "firebase/firestore";


interface ITableData {
    bus: string,
    gcloudLink: string
    time: string
}

export default function DashboardLists() {
    const [busStoppage, setBusStoppage] = useState<Array<ITableData>>([])

    useEffect(() => {
        FirebaseUtil.getInstance().getCurrentUser().then(user => {
            const uid = user?.uid
            if (uid) {
                const stops: Array<ITableData> = []
                const q = query(collection(FirebaseUtil.getInstance().getFirestore(), DATABASES.WHEREABOUTS), where("uid", "==", uid))
                getDocs(q).then(({ docs }) => {
                    console.log(docs)
                    docs.forEach((doc) => {
                        const data = doc.data()
                        stops.push({
                            bus: data.busnumber,
                            gcloudLink: `https://maps.google.com/?q=${data.latitude},${data.longitude}`,
                            time: new Date(data.createdAt.nanoseconds).toLocaleString()
                        })
                    })
                    console.log(docs)
                    setBusStoppage(stops)
                })
            }
        })
    }, [])

    return (
        <ShadowContainer p={2} m={1} mt={0} minWidth="700px">
            <Grid container justifyContent="space-between">
                <Grid item>
                    <Typography
                        style={{ fontWeight: 600, marginLeft: '14px' }}
                        color="textPrimary"
                        variant="subtitle1"
                    >
                        Busses Stoppage
                    </Typography>
                </Grid>
                <Grid item>
                </Grid>
                <Grid item>

                </Grid>
            </Grid>

            <TableContainer component={Paper} elevation={0}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                <Typography variant="subtitle2">Bus Number</Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography variant="subtitle2">Time</Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography variant="subtitle2">View</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(busStoppage.map((item, idx) => (
                            <TableRow key={idx} hover={true}>
                                <TableCell>
                                    <Typography variant="subtitle2">{item.bus}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle2">{item.time}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Link href={item.gcloudLink} target="_blank" rel="noopener">
                                        View on Maps
                                    </Link>
                                </TableCell>
                            </TableRow>
                        )))}
                    </TableBody>
                </Table>
            </TableContainer>

        </ShadowContainer>
    )
}
