import {
    Grid,
    Box,
    Typography,
    Theme,
    useMediaQuery,
    Button,
    useTheme,
} from "@mui/material"
import LineIcon from "../core/components/LineIcon"
import { useNavigate } from 'react-router-dom';
import { useMeQuery } from "../core/api/auth"
import { useState, useEffect } from "react"
import { FirebaseUtil } from "../core/utils/firebase"
// import quilly from "../public/img/login.png"


export default function Home() {

    const theme = useTheme()
    const styles = {
        background: {
            backgroundColor: "#fdf9f3",
        },
        fullHeight: {
            height: "100vh",
            position: "relative"
        },
        margin1T: {
            marginTop: theme.spacing(1),
        },

        margin8T: {
            marginTop: theme.spacing(8),
        },

        middle: {
            marginTop: "20vh",
            [theme.breakpoints.down("md")]: {
                display: "flex",
                flexDirection: "column",
                padding: "2vw",
                top: "25vh",
            },
        },

        customTab: {
            height: "175px",
            width: "185px",
            border: `1px solid ${theme.palette.text.secondary}`,
            display: "flex",
            flexDirection: "column",
            textTransform: "none",
            "& span": {
                display: "flex",
                flexDirection: "column",
            },
            "&:hover": {
                "& i": {
                    color: `${theme.palette.primary.main}!important`,
                },
                "& h6": {
                    color: theme.palette.primary.main,
                },
            },
            "& i": {
                fontSize: theme.typography.h4.fontSize,
                fontWeight: 600,
                color: theme.palette.text.primary,
            },
        },
        margin2L: {
            marginLeft: theme.spacing(2),
        },
    }

    const isPhone = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"))
    const navigate = useNavigate()

    const [error, setError] = useState<string | null>(null)

    const { data, isError, isUninitialized } = useMeQuery("me")

    useEffect(() => {
        FirebaseUtil.getInstance().getCurrentUser().then((user) => {
            if (user) navigate("/")
        })
    }, [data])

    const signin = async () => {
        try {
            const user = await FirebaseUtil.getInstance().login()
            if (user?.email && FirebaseUtil.getInstance().getAuthActiveState()) {
                navigate("/")
            }
        } catch (e: any) {
            console.log(e)
            setError(e?.message || "Something wrong happened...")
        }
    }

    return (
        <Grid container sx={styles.background}>
            {!isPhone && (
                <Grid item md={6} xs={12} sx={styles.fullHeight}>
                    <Box ml={20} >
                        <img style={{ position: "absolute", top: "20%" }} src={"https://busrestro.com/assets/bus_track.png"} alt="quilly" />
                    </Box>
                </Grid>
            )}

            <Grid item md={6} xs={12} sx={styles.fullHeight}>
                <Box sx={styles.middle}>
                    <Typography
                        variant="h3"
                        style={{ fontWeight: 700 }}
                        color="textPrimary"
                    >
                        Sign in.
                    </Typography>
                    <Typography
                        variant="h6"
                        style={{ fontWeight: 400 }}
                        sx={styles.margin1T}
                    >
                        Please contact BusRestro for account.
                    </Typography>

                    <Box sx={styles.margin8T}>
                        <Typography variant="subtitle1">Continue with...</Typography>
                        <Box mt={1}>
                            <Button
                                variant="outlined"
                                color="primary"
                                sx={styles.customTab}
                                onClick={() => signin()}
                            >
                                <LineIcon iconName="lni lni-google" fontWeight={400} />
                                <Typography
                                    variant="subtitle1"
                                    style={{ marginTop: "14px" }}
                                    color="textPrimary"
                                >
                                    Google
                                </Typography>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            {/* {success && (
                <Snackbar
                    open
                    autoHideDuration={6000}
                    onClose={() => setSuccess(null)}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                    <Alert severity="success">{success}</Alert>
                </Snackbar>
            )}
            {error && (
                <Snackbar
                    open
                    autoHideDuration={6000}
                    onClose={() => setError(null)}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                    <Alert severity="error">{error}</Alert>
                </Snackbar>
            )} */}
        </Grid>
    )
}
