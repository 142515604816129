import { configureStore } from "@reduxjs/toolkit"
import { profileAPI } from "../api/auth"

export const makeStore = configureStore({
    reducer: {
        [profileAPI.reducerPath]: profileAPI.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(profileAPI.middleware),

})

export type Store = ReturnType<typeof makeStore.getState>
