import LoginComponent from "./Login"
import DashboardComponent from "./Dashboard"
export const routes = [
    {
        path: '/login',
        component: LoginComponent,
    },
    {
        path: '/',
        component: DashboardComponent
    }
]

